import React from 'react';
import NotSupportedPage from 'src/px/NotSupportedPage';
import { Page } from '../../components';

const ExplorerNotSupportedView = (props) => {
  const state = props.location?.state?.state;
  return (
    <Page
      hideBanner
      context="Explorer"
      metadata={{
        title: 'Explore Health Plans',
        description: 'Get covered for 2023',
        image: '/img/og/explorer.png',
      }}
    >
      <NotSupportedPage state={state} />
    </Page>
  );
};

export default ExplorerNotSupportedView;
