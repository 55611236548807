import React, { useContext } from 'react';
import { SessionContext } from 'src/context/SessionContext';
import { PXLayout, Toolbar } from '../components';
import { STATES } from 'src/utils/states';
import { PXAetnaCvsHealthLogoSvg } from './ZipcodePage';

import { Button } from 'src/components';

const NotSupportedPage = ({ state }: { state: string | undefined }) => {
  const { clearAetnaSession } = useContext(SessionContext);

  return (
    <>
      <PXLayout
        long
        title="Program not supported"
        subtitle="This program is not offered in your area. Continue to shop all available plans instead."
        a
        lightColor="var(--c-purple)"
        darkColor="var(--c-purple)"
        precomponent={
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: '20px',
                paddingTop: '20px',
              }}
            >
              <PXAetnaCvsHealthLogoSvg aria-label="Aetna CVS Health®" />
            </div>
            {!!state ? (
              <img
                src={`/img/states/${STATES[state]?.replace(' ', '_')?.toUpperCase()}.svg`}
                alt={state}
                height={100}
                style={{ filter: 'var(--filter)' }}
              />
            ) : null}
          </>
        }
        toolbar={
          <Toolbar half>
            <div></div>
            <Button
              primary
              onPress={() => {
                clearAetnaSession();
                window.location.href = window.location.origin;
              }}
            >
              Continue
            </Button>
          </Toolbar>
        }
      />
    </>
  );
};

export default NotSupportedPage;
